import React, { useState, useEffect } from 'react';
import { endpoint, wsEndpoint } from '../App';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    SimpleList,
    FilterLiveSearch,
    FunctionField,
    Loading,
    DateInput,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    Form
} from 'react-admin';
import {
    Box, 
    useMediaQuery,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import axios from 'axios';
import { FinancePlanFillForm } from './FinancePlanFillForm';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: accounts,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    const filteredDataToExport = accounts.map(({ avatar, password, connectedToLeads, picture, ...rest }) => rest);

    const ws = XLSX.utils.json_to_sheet(filteredDataToExport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Аналитика - Выгрузка из CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />}>
      Выгрузить в Excel
    </Button>
  );
};

const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Assuming YYYY-MM-DD format for the DateInput
};

const formatTime = (isoString) => {
    const date = new Date(isoString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

const bonusPercent = (sum) => {
    if(sum < 100000){
        return 1.02;
    }
    if(sum >= 100000 && sum < 300000){
        return 1.03;
    }
    if(sum >= 300000 && sum < 500000){
        return 1.04;
    }
    if(sum >= 500000 && sum < 700000){
        return 1.05;
    }
    if(sum >= 700000 && sum < 1000000){
        return 1.06;
    }
    if(sum >= 1000000 && sum < 1500000){
        return 1.07;
    }
    if(sum >= 1500000 && sum < 2000000){
        return 1.08;
    }
    if(sum >= 2000000 && sum < 2500000){
        return 1.09;
    }
    if(sum >= 2500000 && sum < 5000000){
        return 1.1;
    }
    if(sum >= 5000000 && sum < 10000000){
        return 1.12;
    }
    if(sum >= 10000000){
        return 1.15;
    }
}

const teamBonusPercent = (planPercent) => {
    if(planPercent < 40){
        return 1;
    }
    if(planPercent >= 40 && planPercent < 50){
        return 1.01;
    }
    if(planPercent >= 50 && planPercent < 60){
        return 1.0125;
    }
    if(planPercent >= 60 && planPercent < 70){
        return 1.015;
    }
    if(planPercent >= 70 && planPercent < 80){
        return 1.02;
    }
    if(planPercent >= 80 && planPercent < 90){
        return 1.0225;
    }
    if(planPercent >= 90 && planPercent < 100){
        return 1.025;
    }
    if(planPercent >= 100 && planPercent < 150){
        return 1.0275;
    }
    if(planPercent >= 150 && planPercent < 200){
        return 1.03;
    }
    if(planPercent >= 200 && planPercent < 250){
        return 1.0325;
    }
    if(planPercent >= 250){
        return 1.035;
    }
}

const AccountingFilter = ({ setFilters, defaultStartDate, defaultEndDate, currentManagerFilter, handleSelectedManagerFilterChange }) => {
    const [selectedMonth, setSelectedMonth] = useState(new Date());

    const handleApplyFilters = () => {
        const startDate = moment.utc(selectedMonth).startOf('month').toDate();
        const endDate = moment.utc(selectedMonth).endOf('month').toDate();
        
        setFilters({ search_start_time: startDate, search_end_time: endDate });
    };

    return (
        <Form className='filterForm' onSubmit={handleApplyFilters}>
            <Box display="flex" flexDirection="row" mb={2} ml={2} alignItems="center">
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
            <DatePicker
                        views={['year', 'month']}
                        label="Месяц и год"
                        value={selectedMonth}
                        onChange={(newValue) => setSelectedMonth(moment(newValue))}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                        sx={{ marginRight: '1rem' }}
                    />
                     </LocalizationProvider>
                    {localStorage.getItem('role_id') <= 3 &&
                    <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'head_of_dep': true }} >
                        <AutocompleteInput 
                            label="Руководитель" 
                            defaultValue={currentManagerFilter} 
                            onChange={handleSelectedManagerFilterChange} 
                            optionText={(choice) => `${choice.first_name} ${choice.last_name}`} 
                        />
                    </ReferenceInput>}
                    <Button type="submit" variant="contained" color="primary" style={{ marginLeft: '1rem' }}>
                        Показать
                    </Button>
            </Box>
        </Form>
    );
};

const SalariesPage = () => {
    const { identity } = useGetIdentity();
    const [managers, setManagers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [numDays, setNumDays] = useState(1);
    const [filters, setFilters] = useState({
        search_start_time: moment.utc().startOf('month').toDate(),
        search_end_time: moment.utc().endOf('month').toDate(),
    });
    const [currentManagerFilter, setCurrentManagerFilter] = useState(null);

    useEffect(() => {
        fetchData(filters);
    }, []);

    const fetchData = async (filters) => {
        setLoading(true);

        setNumDays(Math.ceil((new Date(filters.search_end_time) - new Date(filters.search_start_time)) / (1000 * 60 * 60 * 24)) + 1);

        try {
            const token = localStorage.getItem('token');
            const id = localStorage.getItem('id');
            const response = await axios.get(
                `${endpoint}/salaries`,
                {
                    params: {
                        manager_account_id: id,
                        team_members_head_id: currentManagerFilter,
                        ...filters
                    },
                    headers: {
                        Authorization: `${token}`
                    }
                }
            );

            if(localStorage.getItem('role_id') > 3){
                // Менеджеры видят только себя
                const filteredManagers =  response.data.filter(manager => manager.id === id);
                setManagers(filteredManagers);
            }else{
                // Остальные видят всё
                const sortedManagers = response.data.sort((a, b) => {
                    if (a.head_of_dep && !b.head_of_dep) return -1;
                    if (!a.head_of_dep && b.head_of_dep) return 1;
                    return b.income_fact - a.income_fact;
                });

                setManagers(sortedManagers);
            }
        } catch (error) {
            console.error('Fetch failed:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSetFilters = (filters) => {
        fetchData(filters);
    };

    const handleSelectedManagerFilterChange = (value) => {
        setCurrentManagerFilter(value);
    };

     // Calculate totals
     const callsPlan = numDays * 26 * managers.length;
     const broadcastPlan = numDays * 1.167 * 3600 * managers.length;
     const onlineMeetingsPlan = Math.round(numDays * 0.4) * managers.length;
     const offlineMeetingsPlan = Math.round(numDays * 0.23) * managers.length;
     const dealsPlan = Math.round(numDays * 0.1) * managers.length;   
     const totalIncomePlan = managers.reduce((sum, manager) => sum + manager.income_plan, 0);

     const callsPlanManager = numDays * 26;
     const broadcastPlanManager = numDays * 1.167 * 3600;
     const onlineMeetingsPlanManager = Math.round(numDays * 0.4);
     const offlineMeetingsPlanManager = Math.round(numDays * 0.23);
     const dealsPlanManager = Math.round(numDays * 0.1);

     //Calculate total finance with bonuses for each manager
     managers.forEach(manager => {
        let totalB = 0;
    
        if (manager.calls >= 800) { // больше 800 звонков
            totalB += 10000;
        }
        if (manager.broadcast_time >= 3600*35) { // больше 35 часов эфира
            totalB += 10000;
        }    
        if (manager.online_meetings >= 12 && manager.online_meetings > 0) {
            totalB += 10000;
        }
        if (manager.offline_meetings >= 7 && manager.offline_meetings > 0) {
            totalB += 10000;
        }
         if (manager.deals >= 3 && manager.deals > 0) {
            totalB += 10000;
        }
    
        manager.total_bonuses = totalB; //итоговые премиальные менеджера
        //процент выполнения плана менеджером
        if(manager.income_fact >= 0 && manager.income_plan > 0){
            manager.percent = (manager.income_fact / manager.income_plan * 100).toFixed(1); 
        }
        if(manager.income_fact > 0 && manager.income_plan <= 0){
            manager.percent = 100;
        }
        if(manager.income_fact <= 0 && manager.income_plan <= 0){
            manager.percent = 0;
        }
        manager.bonus_percent = ((bonusPercent(manager.income_fact) - 1) * 100).toFixed(0); //процент бонусов по количеству прихода в кассу менеджера
        manager.total = (manager.income_fact * manager.bonus_percent/100 + manager.total_bonuses).toFixed(2); //итого к выплате менеджера
    });

    const totalCalls = managers.reduce((sum, manager) => sum + manager.calls, 0);
    const totalBroadcastTime = managers.reduce((sum, manager) => sum +  manager.broadcast_time, 0);
    const totalOnlineMeetings = managers.reduce((sum, manager) => sum + manager.online_meetings, 0);
    const totalOfflineMeetings = managers.reduce((sum, manager) => sum + manager.offline_meetings, 0);
    const totalDeals = managers.reduce((sum, manager) => sum + manager.deals, 0);
    const totalIncomeFact = managers.reduce((sum, manager) => sum + manager.income_fact, 0);

    // Calculate team totals for heads of departments
    managers.forEach(manager => {
        if(manager.head_of_dep){
            let tPlan = 0;
            let tFact = 0;
            let totalTeamHeadBonus = 20000;

            const subManagers = managers.filter(obj => manager.team_members_ids.includes(obj.id));

            let onlineMeetingsTeamPlan = Math.round(numDays * 0.4) * subManagers.length;
            let onlineMeetingsTeamFact = 0;
            let offlineMeetingsTeamPlan = Math.round(numDays * 0.23) * subManagers.length;
            let offlineMeetingsTeamFact = 0;
            let dealsTeamPlan = Math.round(numDays * 0.1) * subManagers.length;   
            let dealsTeamFact = 0;

            subManagers.forEach(sub => {

                tPlan += sub.income_plan;
                tFact += sub.income_fact;

                onlineMeetingsTeamFact += sub.online_meetings;
                offlineMeetingsTeamFact += sub.offline_meetings;
                dealsTeamFact += sub.deals;

            });
        

            manager.team_plan = tPlan; // командный план НЕ включая план ропа
            manager.team_fact = tFact; // командный факт включая план ропа
            // процент выполнения командного плана
            if(manager.team_fact >= 0 && manager.team_plan > 0){
                manager.team_percent = (tFact / tPlan * 100).toFixed(1); 
            }
            if(manager.team_fact > 0 && manager.team_plan <= 0){
                manager.team_percent = 1000;
            }
            if(manager.team_fact <= 0 && manager.team_plan <= 0){
                manager.team_percent = 0;
            }
            manager.bonus_percent = 10; // личный процент бонуса (за свой личный факт)
            manager.team_bonus_percent = teamBonusPercent(manager.team_percent); // командный процент бонуса, от % выполнения плана менеджерами

            if(onlineMeetingsTeamFact >= onlineMeetingsTeamPlan && onlineMeetingsTeamFact > 0){//выполнила ли команда план по онлайн-встречам
                totalTeamHeadBonus += 10000;
            }
            if(offlineMeetingsTeamFact >= offlineMeetingsTeamPlan && offlineMeetingsTeamFact > 0){//выполнила ли команда план по оффлайн-встречам
                totalTeamHeadBonus += 10000;
            }
            if(dealsTeamFact >= dealsTeamPlan && dealsTeamFact > 0){//выполнила ли команда план по количеству сделок
                totalTeamHeadBonus += 10000;
            }

            manager.total_bonuses = totalTeamHeadBonus; //итоговые премиальные РОПа (20к оклад + до 30к из командных показателей)

            manager.total = (manager.income_fact * manager.bonus_percent/100 + manager.total_bonuses + manager.team_fact * (manager.team_bonus_percent - 1)).toFixed(2); //итого к выплате РОПа
        }
    });

    const totalTotal = (managers.reduce((sum, manager) => sum + parseFloat(manager.total), 0)).toFixed(2); // Итого к выплате
    const totalPlan = (managers.reduce((sum, manager) => sum + manager.income_plan, 0)).toFixed(0); // Суммарный план
    const totalFact = (managers.reduce((sum, manager) => sum + manager.income_fact, 0)).toFixed(0); // Суммарный факт
    const totalBonuses = managers.reduce((sum, manager) => sum + manager.total_bonuses, 0); //суммарно премий
    var totalPercent = 0;
    // Суммарный выполнения командного плана
    if(totalFact >= 0 && totalPlan > 0){
        totalPercent = (totalFact / totalPlan * 100).toFixed(2); 
    }
    if(totalFact > 0 && totalPlan <= 0){
        totalPercent = 100;
    }

    return identity ? (
        <Box>
            {localStorage.getItem('role_id') <= 2 && <FinancePlanFillForm />}
            <Box display="flex" flexDirection="row" alignItems="start">
            <h3>Зарплатная ведомость</h3>
            <AccountingFilter 
                setFilters={handleSetFilters} 
                defaultStartDate={filters.search_start_time} 
                defaultEndDate={filters.search_end_time}
                handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                currentManagerFilter={currentManagerFilter} 
            />
            </Box>
            {loading ? (
                <Loading />
            ) : (
                <List sx={{ backgroundColor: 'background.paper' }}>
                    <ListItem sx={{ padding: 0, alignItems: 'stretch', borderBottom: '1px solid lightgrey' }}>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Менеджер" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="План (₽)" secondary={`${totalPlan} ₽`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Факт (₽)" secondary={`${totalFact} ₽`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Выполнение плана" secondary={`${totalPercent}%`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Бонус" secondary={`% от прихода`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Премиальные" secondary={`за выполнение KPI, ₽`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="К выплате (итого, ₽)" secondary={`${totalTotal} ₽`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                    </ListItem>
            {managers.map(manager => {

                    return (
                        <ListItem key={manager.id} sx={{ padding: 0, alignItems: 'stretch' }}>
                            {/*  Менеджер */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${manager.name}`} secondary={manager.head_of_dep ? 'Командные показатели' : ' '} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: manager.head_of_dep ? 'bold' : 'normal' }
                                        }}
                                    />
                                </Box>
                            </Box>

                            {/*  План, ₽ */}       
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                flexDirection="column"
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${manager.income_plan}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>

                                {manager.head_of_dep &&
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${manager.team_plan}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>
                                }

                            </Box>

                            {/*  Факт, ₽ */}              
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                flexDirection="column"
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${manager.income_fact}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold', color: manager.income_fact >=  manager.income_plan && manager.income_fact > 0  ? '#01BF00' : 'default' }
                                        }}
                                    />
                                </Box>

                                {manager.head_of_dep &&
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${manager.team_fact}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>
                                }

                            </Box>

                            {/*  Процент выполнения плана */}                  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                flexDirection="column"
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${manager.percent}%`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold', color: manager.percent > 0  ? '#01BF00' : 'red' }
                                        }}
                                    />
                                </Box>

                                {manager.head_of_dep &&
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${manager.team_percent}%`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>
                                }

                            </Box>

                            {/*  Бонус */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                flexDirection="column"
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey'  
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${manager.bonus_percent}%`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>

                                {manager.head_of_dep &&
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${((manager.team_bonus_percent - 1) * 100).toFixed(1)}%`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>
                                }

                            </Box>

                            {/*  Премиальные */}    
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                flexDirection="column"
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey'  
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${manager.total_bonuses} ₽`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>
                            </Box>

                            {/* Итого */}
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                flexDirection="column"
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${manager.total} ₽`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>
                            </Box>
                        </ListItem>
                    );
                })}
                    <ListItem sx={{ padding: 0, alignItems: 'stretch', borderBottom: '1px solid lightgrey' }}>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Итого:" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="План (₽)" primary={`${totalPlan} ₽`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Факт (₽)" primary={`${totalFact} ₽`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Выполнение плана" primary={`${totalPercent}%`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Бонус" primary={`% от прихода`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Премиальные" primary={`${totalBonuses} ₽`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="К выплате (итого, ₽)" primary={`${totalTotal} ₽`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                    </ListItem>
                </List>
            )}
        </Box>
    ) : (
        <Loading />
    );
};

const ListActions = () => (
    <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>

    </TopToolbar>
);

export default SalariesPage;
