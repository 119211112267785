import * as React from 'react';
import { useState, useEffect, forwardRef, useImperativeHandle, useRef} from 'react';
import {
    useRecordContext,
    useEditContext,
    useCreate,
    useUpdate,
    useNotify,
    useGetIdentity,
    Identifier,
    useResourceContext,
    FileInput, FileField,
    Form,
    CreateParams,
    UpdateParams,
    DataProvider,
    fetchUtils,
    CreateBase,
    SaveButton,
    SimpleForm,
    useCreateContext,
    Toolbar,
    TextInput,
    SelectInput,
    required, 
    DateTimeInput,
    ReferenceInput,
    AutocompleteInput,
    NumberInput,
    DateInput,
} from 'react-admin';
import { Box, TextField, Button, Grid, Card, CardContent, IconButton } from '@mui/material';
import {useFormContext} from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export const AccountingFormInputs = forwardRef((props,  ref) => {
    const editContext = useEditContext();
    const navigate = useNavigate();
    const { reset, setValue, watch } = useFormContext();

    useImperativeHandle(ref, () => ({
        resetForm() {
            // Reset specific form fields
           // setValue('comment', '');
           // setValue('file', '');
           // setValue('date_time', '');
        }
    })); 

    return (
        <Card sx={{ marginTop: '0.7rem' }}>
          <CardContent>
            
            {!editContext.record ? 
              <h3>Меню бухгалтера (внесение информации)</h3>
              : <Box display="flex" flexDirection="row">
                    <IconButton
                        onClick={() => navigate(-1)}
                        size="small"
                    >
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <h3>Меню бухгалтера (изменение информации)</h3>
                </Box>
            }
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <ReferenceInput source="deal_id" reference="deals">
                        {!editContext.record ? 
                            <AutocompleteInput isLoading={true} label="Сделка (введите часть имени клиента или номера сделки)" filterToQuery={search => ({ "q": search })}  optionText={(choice) => `Сделка №${choice.document_name} ${choice.name} - ${choice.manager_name}`} fullWidth validate={required()} />
                             : <AutocompleteInput disabled isLoading={true} label="Сделка (введите часть имени клиента или номера сделки)" filterToQuery={search => ({ "q": search })}  optionText={(choice) => `Сделка №${choice.document_name} ${choice.name} - ${choice.manager_name}`} fullWidth validate={required()} />
                        }
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ReferenceInput source="manager_account_id" reference="sales" >
                            <AutocompleteInput label="Менеджер" filterToQuery={search => ({ "q": search })} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} fullWidth validate={required()} />
                        </ReferenceInput>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <NumberInput label="Плановое поступление, руб." source="income_plan" fullWidth validate={required()}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <NumberInput label="Фактически поступило, руб." source="income_fact" fullWidth validate={required()}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {!editContext.record ? 
                            <DateInput label="Дата" source="date" validate={required()} />
                            : <DateInput label="Дата" source="date" validate={required()} disabled />
                        }
                    </Grid>
                </Grid>
                <TextInput label="Комментарий" helperText={`Например, "перенос из сделки №Ю0123"`} source="comment" fullWidth />
                <Box display="flex" mb={1} mt={1}>
                    <SaveButton 
                        label={!editContext.record ? "Внести информацию" : "Сохранить изменения"}
                        icon={false}
                        redirect={false} 
                        submitOnEnter={false}
                    />
                </Box>
                <div>* При повторном внесении информации в ту же дату по сделке и менеджеру - она перезаписывается полностью, учитывайте это.</div>   
          </CardContent> 
        </Card>
    );
});