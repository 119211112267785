export const stages = [
    {id: 'sale', name: 'В продаже'},
    {id: 'reserved', name: 'В брони (без оплаты)'},
    {id: 'reserved_partly_paid', name: 'Бронь частично оплачена'},
    {id: 'reserved_fully_paid', name: 'Бронь полностью оплачена'},
    {id: 'dealing', name: 'Регистрация сделки (аккредитив)'},
    {id: 'documents_done', name: 'Документы получены'},
    {id: 'sold', name: 'Продано'},
    {id: 'cancelled', name: 'Сделка отменена (расторжение)'},
];
